(function () {
    angular.module('informaApp')
        .service('NewFeatureService', NewFeatureService);

    function NewFeatureService(BaseApiService, ConstantsSvc) {
        return {
            getAllActive() {
                return BaseApiService.get('new-features/active')
                .then(x => x.data.data);
            },
            getAll() {
                return BaseApiService.get('new-features/all')
                .then(x => x.data.data);
            },
            getAllNotRemoved() {
                return BaseApiService.get('new-features')
                .then(x => x.data.data);
            },
            create(data) {
                return {};
            },
            update(data) {
                return {};
            },
            changeActiveStatus(featureId, isActive) {
                return BaseApiService.put('new-features/active', {featureId, isActive});
            },
            changeRemoveStatus(featureId, isRemoved) {
                return BaseApiService.put('new-features/removed', {featureId, isRemoved});
            },
            getById(id) {
                return BaseApiService.put(`new-features/${id}`)
                .then(x => x.data.data);
            }
        }
    }
})();
